<template>
  <div class="enterprise">
    <Header></Header>
    <BaseBanner :banner="'enterprise'"></BaseBanner>
    <div
      ref="animation"
      :class="[
        'enterprise_center_container',
        enterprise_center_container
          ? 'animation_right_start_deial'
          : 'animation_opacity',
      ]"
    >
      <div class="center_container_right">
        <div
          class="container_right_item"
          v-for="(item, index) in infoList"
          :key="index"
          @click="toInfoDetail(item)"
        >
          <div class="info_item_left">
            <div class="day">
              {{ item.time.slice(8, 10) }}
            </div>
            <div class="year">
              {{ item.time.slice(0, 7) }}
            </div>
          </div>
          <div class="info_item_right">
            <div class="info_item_right_title">
              {{ item.title }}
            </div>
            <!--            <div class="info_item_right_content">-->
            <!--              {{item.description}}-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import BaseBanner from '@/components/BaseBanner'
import { mixins } from '@/mixin'
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
export default {
  name: 'Enterprise',
  components: { BaseBanner,Header,Footer },
  mixins:[mixins],
  data(){
    return{
      infoList:[
        {
          time:'2022/12/22',
          title:'喜报！岳泰兴医疗科技荣获国家高新技术企业认证',
          description:'`<div>近日，岳泰兴医疗科技公司荣获国家高新技术企业认定！这是对公司多年来在医疗科技领域持续创新、追求卓越的高度认可，也是对我司持续投入科技研发和创新的肯定。</p><img style="width: 100%;height: 100%;" src="https://image.yuetaixing.com//crmebimage/public/maintain/2023/12/22/3e6830532cb94061abc621fef303e7fdtd20dorg2p.png"/><p style="margin: 20px 0">作为一家专注于医疗大健康科技研发的企业，岳泰兴医疗科技一直致力于推动医疗大健康行业的发展。公司拥有一支高素质、专业化的研发团队，不断投入研发，推出了一系列具有自主知识产权的医疗科技产品，为客户提供了更加便捷、高效、安全的医疗服务。</p><p style="margin: 20px 0">此次获得国家高新技术企业认定，是对岳泰兴医疗科技技术创新能力和成果的认可。公司将继续秉承“用科技推动人类健康”的理念，推动世界医疗大健康事业发展，不断加强技术研发，提升产品品质，为医疗行业的发展贡献更多的力量，成为大健康行业佼佼者。</p></div>`'
        },
        {
          time:'2022/02/14',
          title:'岳泰兴医疗助力雨花防疫工作',
          description:`<div><img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/I1.jpg"/>
                            <p style="margin: 20px 0">湖南岳泰兴医疗科技有限公司董事长晏岳军代表公司分别向长沙市雨花区市场监督管理局、洞井市场监督管理所、洞井街道城管办等单位捐赠了一批用于防疫的75%医用酒精、84消毒液以及医用无菌橡胶手套等物资，受到了各部门领导的热烈欢迎以及衷心感谢。</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/I2.jpg"/>
                            <p style="margin: 20px 0">岳泰兴董事长晏岳军表示：很感谢奋斗在防疫一线的广大基层干部辛勤的付出，作为辖区的企业，岳泰兴很荣幸能为雨花区的防疫工作奉献一份力量！</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/I3.jpg"/>
                           <p style="margin: 20px 0">抗击疫情，人人有责，我们岳泰兴人敢于担起责任。凝心聚力，我们在行动，相信一定会战胜疫情！</p>
                           <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/I4.jpg"/>
                            </div>`
        },
        {
          time:'2022/02/14',
          title:'岳泰兴 | 冬季防疫防传染病首推岳泰兴爆款医用外科口罩​',
          description:`<div><p style="margin: 20px 0">随着气温降低，我国进入了流行性疾病高发的秋冬季，且新冠疫情亦未完全消灭。因此我们建议，秋冬季出行，尤其是前往人群密集的场所，一定要佩戴正规的防护力强口罩如医用外科口罩。经过疫情，全国人民已形成共识，在公共场所佩戴口罩是讲文明、讲卫生的行为，是对自身及他人身体健康负责人的体现。</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/fangyi1.jpg"/>
                            <p style="margin: 20px 0">如今青岛等地又出现了新的疫情，今年注定不平凡。在疫情面前，我们应该提高戴口罩的意识，将戴口罩作为一种文明，先进的体现，既不让人戒备，也不被传染。</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/fangyi2.png"/>
                           <p style="margin: 20px 0">岳泰兴医用外科口罩不仅设计美观，而且携带方便，采用自粘封口设计，是人们居家工作旅行必备之物！医用外科口罩病菌过滤效率大于等于95%，对细菌病菌起到很好的阻隔，对于高风险人流聚集地或从事医院工作建议采用医用外科口罩。</p>
                           <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/fangyi3.jpg"/>
                            <p style="margin: 20px 0">岳泰兴医用外科口罩，各政府单位、企事业单位都在用，您也来采购吧。</p>
                            </div>`
        },
        {
          time: '2022/04/27',
          title: '岳泰兴医疗协助长株潭校友之家及作为家人代表之一向母校岳阳县三中捐赠防疫物资。',
          description: `<div><p style="margin: 20px 0">4月27日，晴朗湛蓝的高空万里无云，由岳泰兴医疗协助长株潭校友之家向岳阳县三中捐赠的防疫物资抵达校园内，受到了各领导的热烈欢迎以及衷心感谢。</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/zhuli1.jpg"/>
                            <p style="margin: 20px 0">对于这次捐赠活动，岳泰兴医疗的董事长晏岳军先生表示能为母校尽到自己一份微薄力量感到非常荣幸。</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/zhuli2.jpg"/>
                           <p style="margin: 20px 0">我们相信：众人拾柴火焰高，点滴汇聚成大海，大家的爱传递的是一份生命的热度，在我们心底埋下了奉献的种子，在疫情面前，用我们的心、我们的爱、我们的真诚为莘莘学子保驾护航!</p>
                           <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/zhuli3.jpg"/>
                            </div>`
        },
        {
          time:'2022-02-25',
          title:'上下一心，春暖花开',
          description:`<div><p style="margin: 20px 0">2月25日，因为公司董事长对门店一线员工的挂念和关怀，也为了鼓舞员工士气，湖南岳泰兴医疗科技有限公司董事长晏岳军一行前往岳泰兴各药房门店慰问督导，并送去疫情防控慰问金。</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/hongbao1.jpg"/>
                            <p style="margin: 20px 0">董事长晏岳军先生对大家在非常时期能够坚守岗位、履职尽责，表示衷心的感谢，感谢大家能够不计个人得失，克服个人困难，服从大局，坚决服从公司安排，做到忠于职守。同时，晏岳军先生表示：大家在值班期间在工作和生活中还有什么困难，尽管向公司反映，公司会全力以赴，尽快落实解决。</p>
                            <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/hongbao2.jpg"/>
                           <p style="margin: 20px 0">最后，董事长晏岳军先生拿出现金红包嘉许了优秀的一线员工们。阳光灿烂，岳泰兴人脸上的笑容更灿烂！</p>
                           <img style="width: 100%;height: 100%;" src="http://www.yuetaixing.com/yuetaixing_image/productImg/hongbao3.jpg"/>
                            </div>`
          },
      ]
    }
  },
  methods:{
    toInfoDetail(item) {
      // const routeUrl = this.$router.resolve({
      //   path: "/infoDetail",
      //   query: {id:item},
      // });
      // window.open(routeUrl.href, "_blank");
      this.$router.push({
        path:'/infoDetail',
        query:{
          info:item  //参数
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.enterprise {
  .enterprise_center_container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 50px 0;

    .center_container_left {
      color: #fff;
      width: 210px;
      line-height: 45px;
      margin-right: 10px;

      .left_top {
        height: 45px;
        background: #00a0e9;
        padding: 0 20px;
      }

      .left_item {
        height: 45px;
        color: #00a0e9;
        padding: 0 30px;
        font-size: 14px;
        border: thin solid rgb(235, 230, 230);
      }
    }

    .center_container_right {
      flex: 1;
      padding: 10px;

      .container_right_item {
        display: flex;
        align-items: center;
        padding: 40px 10px;
        border-bottom: thin dashed rgb(235, 230, 230);
        color: #626971;

        .info_item_left {
          background: hsla(0, 0%, 80%, 0.2);
          width: 76px;
          height: 72px;
          margin-right: 15px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .day {
            font-size: 24px;
          }

          .year {
            color: #626971;
            font-size: 14px;
          }
        }

        .info_item_right {
          flex: 1;
          font-size: 14px;

          .info_item_right_title {
            font-size: 18px;
            color: #000000;
            margin-bottom: 20px;
            cursor: pointer;
          }

          .info_item_right_title:hover {
            color: #00a0e9;
          }

          .info_item_right_content {
            line-height: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>